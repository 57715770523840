<template lang="pug">
  v-col(cols=12 md=4 ).pa-0 
    //-Móvil 
    
    v-row.justify-center.align-center.d-md-none
      v-col.d-none.d-md-block.mr-12-pr-12
        v-container(align='center' justify='center').ml-8
            v-row.mb-3.ml-1
                h2.white--text.mr-1.font-weight-light Mapa de sitio
            v-row.ma-0.pa-0.ml-1(v-for='{texto, ruta}, i in Pie_de_página.mapa_de_sitio' :key='i'  @click='dirigir_a(ruta)')
                v-icon(v-if='ruta==ruta_actual' x-small left color='primario' dark absolute) fas fa-angle-right
                v-icon(v-else x-small left color='white' dark absolute) fas fa-angle-right
                div.puntor
                  h3(v-if='ruta==ruta_actual').primario--text.font-weight-light {{texto}}
                  h3(v-else).white--text.font-weight-light {{texto}}

    //-PC
    v-col.d-none.d-md-block.ml-12
      v-container
        v-row.justify-start.align-start.mb-2.ml-1.ml-12
          h2.white--text.mr-1.font-weight-light Mapa de sitio
        v-row.ma-0.pa-0.ml-12(v-for='{texto, ruta}, i in Pie_de_página.mapa_de_sitio' :key='i'  @click='dirigir_a(ruta)')
          v-icon(v-if='ruta==ruta_actual' x-small left color='primario' dark absolute) fas fa-angle-right
          v-icon(v-else x-small left color='white' dark absolute) fas fa-angle-right
          div.puntor
            h3(v-if='ruta==ruta_actual').primario--text.font-weight-light {{texto}}
            h3(v-else).white--text.font-weight-light {{texto}}


</template>
<script>
export default {
  computed: {
    ruta_actual(){
        return this.$route.name
    },
  },
  methods: {
    dirigir_a(ruta){
      if(ruta!="servicios") this.$router.replace({name: ruta, params: {}})
      else this.$router.replace({name: "construcción", params: {}})
      this.$vuetify.goTo("#navegador")
    },
  },
}
</script>